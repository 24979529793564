import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Text,
  Flex,
  useTheme,
  useColorMode,
} from '@chakra-ui/react';
import {
  IconDropdownArrow,
} from './Icons';

// Controlled Component
const Selector = ({
  options,
  disabled,
  readOnly,
  value,
  handleSelect,
  inputRef,
}) => {
  const {colorMode} = useColorMode();
  const theme = useTheme();
  const [isOpen, setOpen] = useState(false);

  const toggleDropdown = () => {
    setOpen(!isOpen);
  };

  const handleItemClick = value => {
    handleSelect(value);
    setOpen(false);
  };
  const clickHandler = e => {
    if (e.target.classList.contains('selector')) return;
    setOpen(false);
  };
  useEffect(() => {
    document.removeEventListener('click', clickHandler);
    if (isOpen) document.addEventListener('click', clickHandler);
    return () => {
      document.removeEventListener('click', clickHandler);
    };
  }, [isOpen]);

  // eslint-disable-next-line eqeqeq
  const selectedValue = options.find(item => item.value == value) || {};

  return (
    <Box
      ref={inputRef}
      w="100%"
      borderRadius="5px"
      color={`${colorMode}.grey.50`}
      backgroundColor={!disabled ? `${colorMode}.bg.800` : `${colorMode}.bg.500`}
      border="1px"
      borderColor={`${colorMode}.bg.200`}
      className='dropdown selector'
      position="relative"
      userSelect="none">
      <Flex
        className='selector'
        cursor={disabled ? 'not-allowed' : 'pointer'}
        justify="space-between"
        align="center"
        onClick={(disabled || readOnly) ? undefined : toggleDropdown}
        pl={3}
        pr={2}
        py={2}>
        <Text as="span"
          className='selector'
          variant="product-body">
          {selectedValue.display ? selectedValue.display : selectedValue.label || ''}</Text>
        <Box py="6px" pl="6px" pr="3px"
          className='selector'
          borderLeft="1px"
          borderColor={`${colorMode}.bg.200`}>
          <IconDropdownArrow color={theme.colors[colorMode].grey[300]} />
        </Box>
      </Flex>
      <Box p={2}
        className='selector'
        borderTop="1px"
        borderColor={`${colorMode}.bg.200`}
        display={isOpen ? 'block' : 'none'}
        position="absolute"
        zIndex="10"
        backgroundColor={`${colorMode}.bg.800`}
        w="100%"
        maxH="15rem"
        overflow="auto">
        {options && options.map((item, i) => (
          <Text
            className='selector'
            key={`opt-${i}`}
            cursor="pointer"
            p={2}
            _hover={{backgroundColor: theme.colors[colorMode].grey[700]}}
            onClick={(disabled || readOnly) ? undefined : () => handleItemClick(item.value)}
            variant="product-body">{item.display ? item.display : item.label}</Text>
        ))}
      </Box>
    </Box>
  );
};

Selector.propTypes = {
  options: PropTypes.array,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  defaultValue: PropTypes.string,
  handleSelect: PropTypes.func,
};

export default Selector;
