import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  useColorMode,
  useTheme,
} from '@chakra-ui/react';
import Markdown from 'markdown-to-jsx';

const MarkdownComponent = ({
  content = '',
  boxCss = {},
  px = 0,
}) => {
  const {colorMode} = useColorMode();
  const {colors} = useTheme();
  const css = {
    a: {color: colors[colorMode].grey[100]},
    ...boxCss,
  };
  return (
    <Box px={px} sx={{'*': {all: 'revert'}}} css={css}>
      <Markdown>{content}</Markdown>
    </Box>
  );
};

MarkdownComponent.propTypes = {
  content: PropTypes.string,
  boxCss: PropTypes.object,
  px: PropTypes.number,
};

export default MarkdownComponent;
